import '../css/webflow.css'
import '../css/normalize.css'
import '../css/life-group.webflow.css'
import axios from 'axios';
// import './404.css'
import logo_eva_arrow from '../images/eva_arrow.png'
import { useState, useEffect } from 'react';
import { Document,Page } from 'react-pdf/dist/esm/entry.webpack';
import pdfFile from './lic_uk.pdf'

const About = () => {  
  const [visible404, setVisible404] = useState(0);
  const [housesT, setHouseT] = useState([]);
  const [documentTarif, setDocumentTarif] = useState([]);
  const [documentTarifId, setDocumentTarifId] = useState([]);
  const [idHouse, setIdHouse] = useState(null);
  const [nameHouse, setNameHouse] = useState(null);


  async function getHouse() {
      await axios.get(`${window.origin}/api/objects`)  
          .then(res => {
              setHouseT(res.data);
          });
  }

  async function getDocumentTarif() {
    await axios.get(`${window.origin}/api/documenttarifs`)  
        .then(res => {
            setDocumentTarif(res.data);
          //   console.log(res.data)
        });
  }

  useEffect( () => {
      getHouse();
      getDocumentTarif();
      }, []);


  return (
    <div class="section-2 wf-section">
    <div class="container-9 w-container">
      <h1 class="heading-man">ООО УК «Лайф Групп»</h1>
      <div class="div-block-22">
        <div class="div-block-23">
          <div class="div-block-25" onClick={() => setVisible404(1)} style={{cursor:'pointer'}}><img src={logo_eva_arrow} loading="lazy" alt=""/>
            <a class="link-sidebar">Вакансии</a>
          </div>
          <div class="div-block-25" onClick={() => setVisible404(2)} style={{cursor:'pointer'}}><img src={logo_eva_arrow} loading="lazy" alt=""/>
            {/* <a href="/" class="link-sidebar">Тарифы</a> */}
            <a class="link-sidebar">Тарифы</a>
          </div>
          <div class="div-block-25" onClick={() => setVisible404(3)} style={{cursor:'pointer'}}><img src={logo_eva_arrow} loading="lazy" alt=""/>
            <a class="link-sidebar">Документы</a>
          </div>
          <div class="div-block-25" onClick={() => setVisible404(4)} style={{cursor:'pointer'}}><img src={logo_eva_arrow} loading="lazy" alt=""/>
            <a class="link-sidebar">Реквизиты</a>
          </div>
          <div class="div-block-25"><img src={logo_eva_arrow} loading="lazy" alt=""/>
            <a href="/newsList" class="link-sidebar">Информация для жителей</a>
          </div>
        </div>
        <div class="div-block-24">
          
        {visible404===1?
            <div class="site">
           
            <h1 onClick={() => setVisible404(false)} style={{cursor:'pointer'}}>Внимание:
              <small>Страница в разработке 1</small></h1>
            </div>
          :<></>  
        }    
        {visible404===2?
            <div class="site">
           
            <div class="w-layout-grid grid">
                          {housesT.map((house, index) => (
                              <div class="div-block-6">
                                      <a onClick={() => {setVisible404(21); 
                                                        setIdHouse(house.id); 
                                                        setNameHouse(house.adres);
                                                        setDocumentTarifId(documentTarif.filter(item => item.id_house === house.id));
                                                      }} 
                                        class="link-block-2 w-inline-block" style={{cursor:'pointer'}}>
                                        <img src={house.logo_2} loading="lazy" width="103" alt="" class="image-6"/>
                                          <div class="object-link">{house.adres}</div>
                                      </a>
                              </div>
                        ))}
                    </div>
            </div>
          :<></>  
        }    
        {visible404===21?
            <div class="site">
              <div >
                <div>Тарифы на услуги по МКД: <b>{nameHouse}</b></div>  
                {documentTarifId.map((documens, index) => (
                                              <div>
                                                <a href={`/api/documenttarifs/download/${documens.id}`} class="link5-6">
                                                    {documens.name}
                                                </a>
                                              </div>  
                              ))}
              </div>
            </div>
          :<></>  
        }    
        {visible404===3?
            <div class="site">
              <Document file={pdfFile}>
                  <Page pageNumber={1} />
              </Document>
              <p/>
              <a href={require("./Polozh.pdf")} target="blank">
                Положение об обработке и защите персональных данных
              </a> 
              <p/>
              <a href={require("./Prikaz.pdf")} target="blank">
                Приказ об утверждении положения об обработке и защите персональных данных
              </a> 
              <p/>
              <a href={require("./standart_contract.pdf")} target="blank">
                Типовой договор об управление многоквартирными домом
              </a> 

            </div>
          :<></>  
        }    
        {visible404===4?
            <div class="site">
              <p style={{textAlign: 'center'}}><span style={{fontWeight: 400}}>Карточка предприятия&nbsp;</span></p>
              <p style={{textAlign: 'center'}}><strong>ООО УК &laquo;Лайф Групп&raquo;</strong></p>
              <table style={{width: '740px'}}>
              <tbody>
              <tr>
              <td style={{width: '316.062px'}}><br />
              <p><span style={{fontWeight: 400}}>Полное наименование предприятия</span></p>
              </td>
              <td style={{width: '405.938px'}}><br />
              <p><span style={{fontWeight: 400}}>Общество с ограниченной ответственностью&nbsp; Управляющая компания &laquo;Лайф Групп &raquo;</span></p>
              </td>
              </tr>
              <tr>
              <td style={{width: '316.062px'}}><br />
              <p><span style={{fontWeight: 400}}>Юридический адрес</span></p>
              </td>
              <td style={{width: '405.938px'}}><br />
              <p><span style={{fontWeight: 400}}>620146, Свердловская обл., г. Екатеринбург, ул. Шаумяна, дом № 87, 1 этаж офис УК</span></p>
              </td>
              </tr>
              <tr>
              <td style={{width: '316.062px'}}><br />
              <p><span style={{fontWeight: 400}}>Номер расчетного счета</span></p>
              </td>
              <td style={{width: '405.938px'}}><br />
              <p><span style={{fontWeight: 400}}>40702810716000052488</span></p>
              </td>
              </tr>
              <tr>
              <td style={{width: '316.062px'}}><br />
              <p><span style={{fontWeight: 400}}>Номер корреспондентского счета</span></p>
              </td>
              <td style={{width: '405.938px'}}><br />
              <p><span style={{fontWeight: 400}}>30101810500000000674</span></p>
              </td>
              </tr>
              <tr>
              <td style={{width: '316.062px'}}><br />
              <p><span style={{fontWeight: 400}}>БИК</span></p>
              </td>
              <td style={{width: '405.938px'}}><br />
              <p><span style={{fontWeight: 400}}>046577674</span></p>
              </td>
              </tr>
              <tr>
              <td style={{width: '316.062px'}}><br />
              <p><span style={{fontWeight: 400}}>Полное наименование банка</span></p>
              </td>
              <td style={{width: '405.938px'}}><br />
              <p><span style={{fontWeight: 400}}>Уральский банк ПАО Сбербанк&nbsp;</span></p>
              </td>
              </tr>
              <tr>
              <td style={{width: '316.062px'}}><br />
              <p><span style={{fontWeight: 400}}>Местонахождение банка</span></p>
              </td>
              <td style={{width: '405.938px'}}><br />
              <p><span style={{fontWeight: 400}}>Г. Екатеринбург</span></p>
              </td>
              </tr>
              <tr>
              <td style={{width: '316.062px'}}><br />
              <p><span style={{fontWeight: 400}}>ИНН</span></p>
              </td>
              <td style={{width: '405.938px'}}><br />
              <p><span style={{fontWeight: 400}}>6658476470</span></p>
              </td>
              </tr>
              <tr>
              <td style={{width: '316.062px'}}><br />
              <p><span style={{fontWeight: 400}}>КПП</span></p>
              </td>
              <td style={{width: '405.938px'}}><br />
              <p><span style={{fontWeight: 400}}>665801001</span></p>
              </td>
              </tr>
              <tr>
              <td style={{width: '316.062px'}}><br />
              <p><span style={{fontWeight: 400}}>ОГРН</span></p>
              </td>
              <td style={{width: '405.938px'}}><br />
              <p><span style={{fontWeight: 400}}>1156658063121</span></p>
              </td>
              </tr>
              <tr>
              <td style={{width: '316.062px'}}><br />
              <p><span style={{fontWeight: 400}}>Дата&nbsp; регистрации</span></p>
              </td>
              <td style={{width: '405.938px'}}><br />
              <p><span style={{fontWeight: 400}}>18.06.2018</span></p>
              </td>
              </tr>
              <tr>
              <td style={{width: '316.062px'}}><br />
              <p><span style={{fontWeight: 400}}>ОКТМО</span></p>
              </td>
              <td style={{width: '405.938px'}}><br />
              <p><span style={{fontWeight: 400}}>65701000</span></p>
              </td>
              </tr>
              <tr>
              <td style={{width: '316.062px'}}><br />
              <p><span style={{fontWeight: 400}}>Генеральный директор</span></p>
              </td>
              <td style={{width: '405.938px'}}>
              <p><span style={{fontWeight: 400}}>Шевелев Антон Евгеньевич</span></p>
              </td>
              </tr>
              </tbody>
              </table>
              <p>&nbsp;</p>
              <p><span style={{fontWeight: 400}}>Предприятие применяет упрощенную систему налогообложения.</span></p>
            </div>
          :<></>  
        }    
         {visible404===0? 
          <div class="w-richtext">
              <h4>Управляющая компания ООО УК «Лайф Групп» создана в сентябре 2016 года.</h4>
              <p>В процессе осуществления своей деятельности по управлению многоквартирными домами, мы (УК «ЛГ» ) руководствуется Жилищным кодексом
              Российской Федерации, Гражданским кодексом Российской Федерации, Постановлениями Правительства Российской Федерации и иными нормативно — правовыми актами, регулирующими жилищные отношения в рамках действующего законодательства Российской Федерации.</p>
              <p>Основными направлениями деятельности управляющей компании являются:</p>
              <ul>
                <li>содержание общего имущества собственников помещений в надлежащем состоянии (техническое обслуживание инженерных коммуникаций,
              оборудования и устройств, включая аварийно-диспетчерскую службу;</li>
                <li>периодические осмотры общего имущества; подготовка к сезонной
              эксплуатации; и т.д);</li>
                <li>создание комфортных и безопасных условий проживания;</li>
                <li>качественное предоставление жилищно-коммунальных услуг;</li>
              </ul>
              <p>Основной вид деятельности управляющей организации:</p>
              <ul>
                <li>выполнение работ по содержанию и ремонту общего имущества в многоквартирных домах в надлежащем состоянии (техническое обслуживание инженерных коммуникаций, оборудования и устройств, включая аварийно – диспетчерское;</li>
                <li>регулярные осмотры общего имущества; подготовка к сезонной эксплуатации; и т.д.);</li> 
                <li>оказание услуг и выполнение работ по управлению многоквартирными домами (организация эксплуатации, заключение договоров с ресурсоснабжающими и специализированными организациями (обслуживание лифтов и др.);</li> 
                <li>организация предоставления коммунальных услуг, в том числе на содержание общего имущества;</li> 
                <li>осуществление иной деятельности, направленной на создание комфортных и безопасных условий проживания.</li>
              </ul>    
                <p>Управление многоквартирными жилыми домами осуществляется на основании заключенных договоров в соответствии с протоколами общих
              собраний собственников помещений домов.</p>
                <p>В настоящий момент в управлении ООО Управляющая компания «Лайф Групп» находится 16 многоквартирных домов г. Екатеринбурга
              В ООО УК «Лайф Групп» работают специалисты высокого профессионального уровня, регулярно повышающие свою квалификацию.</p> 
                <p>Проводится мониторинг экономической ситуации в сфере жилищно-коммунального хозяйства с целью привлечения добросовестных подрядчиков, оптимизации
              работ и стоимости услуг.</p>
                <p>Управляющие уделяют особое внимание взаимодействию с жителям, тесно сотрудничая с Советами домов и старшими по МКД по вопросам
              содержания и текущего ремонта общего имущества собственников, благоустройства и озеленения территорий, совместно решаются вопросы.
              Накопленный опыт работы, высококвалифицированные специалисты, четкая организация деятельности служб и подразделений позволяют компании
              выполнять взятые на себя обязательства.</p> 
                <p>С целью улучшения качества оказываемых услуг и более быстрого реагирования на обращения жителей ООО УК «Лайф Групп» разработан сайт, в
              определённом разделе которого Вы можете задать свои вопросы, оставить отзывы и пожелания, ознакомиться с последними новостями и получать
              достоверную и своевременную информацию в сфере жилищно – коммунального хозяйства.</p>
          </div>
          :<></>  
          }
        </div>
      </div>
    </div>
  </div>
)}

export default About;
